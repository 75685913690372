
import { defineComponent } from 'vue';

import FormField from '@/components/forms/FormField.vue';

import { Getters, Actions } from '@/store/enums/BundleEnums';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'active-select',
  components: {
    FormField,
  },
  emits: ['change', 'close'],
  props: {
    containerClass: {
      default: 'col-lg-4',
      type: String,
    },
    name: {
      default: 'active',
      type: String,
    },
    label: {
      default: 'Active',
      type: String,
    },
    value: {
      default: '',
      type: String,
    },
  },

  async mounted() {
    await this.fetchBundles();
  },
  computed: {
    ...mapGetters({
      allBundles: Getters.GET_ALL_BUNDLES,
    }),

    formatOptions() {
      const optionsObj =
        typeof this.allBundles !== 'undefined'
          ? this.allBundles.map((item) => {
              const price = parseFloat(item?.amount).toFixed(2);
              const label = item.description;
              return {
                label: `${price} (${label})`,
                value: item.id,
              };
            })
          : [];

      return optionsObj;
    },
  },
  methods: {
    ...mapActions({
      fetchBundles: Actions.FETCH_ALL_BUNDLES,
    }),
    handleChange(value) {
      this.$emit('change', value);
    },
    handleClose() {
      this.$emit('close');
    },
  },
});
