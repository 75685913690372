import { createValidationObjectSchema } from '@/utils/validations';

export const DrawBundleSchema = createValidationObjectSchema({
  account_plan_bundle_id: {
    errorTextFieldLabel: 'Bundle Price',
    required: true,
  },
  quantity: {
    errorTextFieldLabel: 'No of Entries',
    required: true,
  },
  active: {
    errorTextFieldLabel: 'Active',
    required: true,
  },
  description: {
    errorTextFieldLabel: 'Description',
    required: true,
  },
});
