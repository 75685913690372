
import { defineComponent } from 'vue';
import toolbarOptions from '@/utils/toolbars';
import Editor from '@tinymce/tinymce-vue';
import { Template } from '@/models/TemplateModel';

const apiKey = process.env.VUE_APP_TINY_MCE_KEY;

const initValues = {
  body: '',
} as {} as unknown as Template;

export default defineComponent({
  name: 'tiny-mce-editor-form',
  emits: ['update:modelValue'],
  props: ['modelValue'],
  components: {
    Editor,
  },
  data: () => ({
    apiKey: apiKey,
    formData: initValues,
    currentCursorSelection: 0,
    subjectInput: {} as typeof HTMLInputElement,
    updateMode: false,
    previewModel: '',
    isEdit: '',
    currentlyActiveField: '',
    config: {
      destroy: true,
      selector: 'textarea',
      auto_focus: 'input',
      menubar: '',
      toolbar: toolbarOptions.join(' '),
      height: '500',
      plugins: 'code',
      contextmenu: 'link image table',
    },
  }),
  mounted() {
    console.log('apiKey', apiKey);
    this.subjectInput = this.$refs.subjectInput as typeof HTMLInputElement;
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleElementFocus($event, field) {
      this.currentlyActiveField = field;

      if (field == 'subjectInput') {
        this.currentCursorSelection = $event.target.selectionStart ?? 0;
      }
    },
    handleElementCursor($event) {
      this.currentCursorSelection = $event.target.selectionStart ?? 0;
    },
  },
});
