
import { defineComponent } from 'vue';

import { mapActions, mapGetters } from 'vuex';

import { acronym, ucwords } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';
import { Draw } from '@/models/DrawModel';
import {
  Getters as BundleGetters,
  Actions as BundleActions,
} from '@/store/enums/PlanBundleEnums';

export default defineComponent({
  name: 'detail-tab-content',
  props: {
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
  },
  data: () => ({
    bundlesTable: {
      currentPage: 1,
      totalPages: 10,
      perPage: 15,
      total: 0,
      items: [],
      ordering: false,
    },

    loading: false,
    loadingSave: false,
    loadingDetails: false,
  }),
  computed: {
    ...mapGetters({
      bundles: BundleGetters.GET_PLAN_BUNDLES,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
  },
  methods: {
    ...mapActions({
      fetchBundles: BundleActions.FETCH_PLAN_BUNLDES,
      addBundle: BundleActions.STORE_PLAN_BUNLDES,
      updateBundle: BundleActions.UPDATE_PLAN_BUNLDES,
      deleteBundle: BundleActions.DELETE_PLAN_BUNLDES,
    }),

    formatUCwords(str) {
      return ucwords(str.replace(/_/g, ' '));
    },

    async getBundles() {
      this.loading = true;

      await this.fetchBundles({ draw_id: this.$route.params.draw_id }).then(
        () => {
          this.loading = false;
        }
      );
    },

    async createBundles() {
      if (this.loadingSave) return;

      this.loadingSave = true;

      await this.fetchBundles({ draw_id: this.$route.params.draw_id }).then(
        () => {
          this.loadingSave = false;
        }
      );
    },

    onPageChanged() {
      console.log('test');
    },

    getAcronym(name = '') {
      return acronym(name);
    },
  },
});
