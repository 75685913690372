import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createBlock(_component_Editor, {
    style: {"min-width":"100%"},
    "output-format": "html",
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    "api-key": _ctx.apiKey,
    init: _ctx.config,
    ref: "editorInput",
    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleElementFocus($event, 'editorInput')))
  }, null, 8, ["modelValue", "api-key", "init"]))
}