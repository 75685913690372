import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-33714ec0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb-container" }
const _hoisted_2 = {
  key: 0,
  class: "fas fa-chevron-right icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, key) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: key,
        class: _normalizeClass(["breadcrumb-link", { 'text-black': !item.current }]),
        to: item.to
      }, {
        default: _withCtx(() => [
          (key > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.text), 1)
        ]),
        _: 2
      }, 1032, ["to", "class"]))
    }), 128))
  ]))
}