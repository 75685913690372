import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-10 mb-xxl-8 d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-row p-3"
}
const _hoisted_3 = { class: "w-15" }
const _hoisted_4 = { class: "ps-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.draw, (value, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (
          !['draw_entries', 'draw_user_entries', 'metadata'].includes(index)
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatUCwords(index)), 1),
              _createElementVNode("div", null, [
                _createTextVNode(" : "),
                _createElementVNode("span", _hoisted_4, _toDisplayString(value), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}