import { createValidationObjectSchema } from '@/utils/validations';

export const SendMail = createValidationObjectSchema({
  delivery_date: {
    required: true,
  },
  delivery_time: {
    required: true,
  },
  subject: {
    required: true,
  },
});

export const Templates = createValidationObjectSchema({
  send_when: {
    required: true,
  },
  no_of_days: {
    required: true,
  },
});

export const Reminders = createValidationObjectSchema({
  send_when: {
    required: true,
  },
  no_of_days: {
    required: true,
  },
});
