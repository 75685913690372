
import { defineComponent } from 'vue';
import {
  Actions as DrawActions,
  Getters as DrawGetters,
  Mutations as DrawMutations,
} from '@/store/enums/DrawEnums';
import { Mutations as BundleMutations } from '@/store/enums/PlanBundleEnums';
import { Actions as DrawBundleActions } from '@/store/enums/DrawBundleEnums';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { acronym } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';
import Card from '@/views/new-design/components/Card.vue';
import { Draw } from '@/models/DrawModel';
import Chip from '@/components/Chip.vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import Details from '@/views/new-design/pages/Draws/partials/Details.vue';
import Bundles from '@/views/new-design/pages/Draws/partials/Bundles.vue';
import DrawEntries from '@/views/new-design/pages/Draws/partials/DrawEntries.vue';
import UserEntries from '@/views/new-design/pages/Draws/partials/UserEntries.vue';
import Winner from '@/views/new-design/pages/Draws/partials/Winner.vue';
import SendMail from '@/views/new-design/pages/Draws/partials/SendMail.vue';

import {
  Actions as MemberActions,
  Getters as MemberGetters,
} from '@/store/enums/MemberEnums';
import { Actions as PlanActions } from '@/store/enums/PlanEnums';
import {
  Actions as UserEntryActions,
  Getters as UserEntryGetters,
} from '@/store/enums/UserEntryEnums';
import { Actions as DrawEntryActions } from '@/store/enums/DrawEntryEnums';
import { Actions as DrawWinnerActions } from '@/store/enums/DrawWinnerEnums';
import { Actions as TemplateActions } from '@/store/enums/TemplateEnums';
import ImageUploadModal from '@/components/ImageUploadModal.vue';
import toasts from '@/utils/toasts';
import { UserEntry } from '@/models/UserEntryModel';
import timeUtil from '@/utils/time';

import moment from 'moment-timezone';
import { Picture as IconPicture } from '@element-plus/icons-vue';

const drawInit = {} as unknown as Draw;

export default defineComponent({
  name: 'view-draw',
  components: {
    Chip,
    Breadcrumb,
    Card,
    Details,
    Bundles,
    DrawEntries,
    UserEntries,
    Winner,
    SendMail,
    ImageUploadModal,
    IconPicture,
  },
  data: () => ({
    loading: false,
    loadingDetails: false,
    loadingClose: false,
    loadingOpen: false,
    loadingInit: false,
    loadingRun: false,
    counterTimer: '',
    startDraw: false,
    showWinner: false,
    drawData: drawInit,
    drawLotData: {} as any,
    drawButtonEnable: false,
    activeMenu: 'details',
  }),
  async mounted() {
    if (!this.drawData?.id) {
      await this.getDraw();
    }

    if (['new', 'open', 'close'].includes(this.drawData?.status)) {
      await this.getMembers();

      await this.getUserEntries();
    }
  },
  computed: {
    ...mapGetters({
      draw: DrawGetters.GET_DRAW,
      errors: DrawGetters.GET_ERRORS,
      drawActionErrors: DrawGetters.GET_ACTION_ERRORS,
      members: MemberGetters.GET_ALL_MEMBERS,
      userEntries: UserEntryGetters.GET_USER_ENTRIES,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    menuTitles() {
      return {
        details: 'Overview',
        bundles: 'Bundles',
        user_entries: 'User Entries',
        draw_entries: 'Draw Entries',
        winners: 'Winners',
        send_mail: 'Send Mail',
      };
    },

    sectionTitle() {
      return this.menuTitles[this.activeMenu];
    },

    breadcrumbs() {
      return [
        {
          to: '/draws',
          text: 'Draws',
          current: false,
        },
        {
          to: `/draws/${this.$route.params.draw_id}`,
          text: 'Draw Details',
          current: true,
        },
      ];
    },

    membersCount() {
      if (['initialized', 'drawn'].includes(this.draw.status)) {
        return this.draw.metadata?.members ? this.draw.metadata?.members : 0;
      }

      return this.members?.length;
    },

    userEntryCount() {
      if (['initialized', 'drawn'].includes(this.draw.status)) {
        return this.draw.draw_user_entries_count
          ? this.draw.draw_user_entries_count
          : 0;
      }

      return Object.values(this.userEntries)
        .map((item) => {
          const entryItem = item as unknown as UserEntry;
          return parseFloat(entryItem.quantity);
        })
        .reduce(function (a: any, b: any) {
          return parseFloat(a) + parseFloat(b);
        }, 0);
    },

    drawEntryCount() {
      return this.draw.draw_entries_count ? this.draw.draw_entries_count : 0;
    },

    getCountdown() {
      return this.draw.time_counter
        ? this.draw.time_counter * 24 * 60 * 60 * 1000
        : 0;
    },

    getDaysRemaining() {
      const { nowDateTime, drawDateTime } = this.drawDateTime();

      const timeDiffFromNow = drawDateTime.fromNow();
      const timeDiffDays = drawDateTime.diff(nowDateTime, 'days');

      if (['close'].includes(this.draw.status)) {
        return `Draw has been closed`;
      }

      if (
        ['open'].includes(this.draw.status) &&
        drawDateTime.isBefore(nowDateTime)
      ) {
        return `Draw started ${this.draw?.days_lapse_text}`;
      }

      if (['initialized'].includes(this.draw.status)) {
        return `Preparing to start draw`;
      }

      if (timeDiffDays > 6 && ['open'].includes(this.draw.status)) {
        return `Draw starts ${this.draw?.days_left_text}`;
      }

      return `Draw starts ${timeDiffFromNow}`;
    },
  },
  methods: {
    ...mapActions({
      fetchMembers: MemberActions.FETCH_ALL_MEMBERS,
      fetchUserEntries: UserEntryActions.FETCH_USER_ENTRIES,
      fetchDrawEntries: DrawEntryActions.FETCH_DRAW_ENTRIES,
      fetchBundles: DrawBundleActions.FETCH_DRAW_BUNLDES,
      fetchWinners: DrawWinnerActions.FETCH_DRAW_WINNERS,
      fetchTemplates: TemplateActions.FETCH_ALL_TEMPLATES,
      fetchDraw: DrawActions.FETCH_DRAW,
      updateDraw: DrawActions.UPDATE_DRAW,
      clearDraw: DrawActions.CLEAR_DRAW,
      uploadDrawImage: DrawActions.UPLOAD_DRAW_PICTURE,
      uploadWinnerImage: DrawWinnerActions.UPLOAD_DRAW_WINNER_PICTURE,
      fetchPlans: PlanActions.FETCH_PLANS,
      initializeDraw: DrawActions.INITIALIZE_DRAW_LOTS,
    }),

    ...mapMutations({
      setDraw: DrawMutations.SET_PAGINATION,
      setBundlePagination: BundleMutations.SET_PAGINATION,
    }),

    drawDateTime() {
      const timezone = process.env.VUE_APP_DEFAULT_TIMEZONE;

      const drawDate = moment(`${this.draw?.draw_date}`).format('YYYY-MM-DD');
      const drawTime = moment(`${this.draw?.draw_time}`, 'hh:mmA').format(
        'hh:mm:ss'
      );

      const drawDateTime = moment(`${drawDate} ${drawTime}`);
      const nowDateTime = moment().tz(timezone);

      return {
        drawDateTime,
        nowDateTime,
      };
    },

    getStatus(data: Draw) {
      if (data?.status == 'drawn') {
        return {
          type: 'primary',
          text: 'Drawn',
          value: true,
          textStatus: '',
        };
      }

      if (data?.status == 'open') {
        return {
          type: 'success',
          text: 'Open',
          value: true,
          textStatus: '',
        };
      }

      if (data?.status == 'initialized') {
        return {
          type: 'success',
          text: 'Initialized',
          value: true,
          textStatus: '',
        };
      }

      if (data?.status == 'new') {
        return {
          type: 'dark',
          text: 'New',
          value: true,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Closed', value: false };
    },

    async showTableData(menu) {
      this.activeMenu = menu;

      switch (menu) {
        case 'bundles':
          await this.getBundles();
          break;
        case 'user_entries':
          if (['initialized', 'drawn'].includes(await this.draw.status)) return;

          await this.getUserEntries();
          break;
        case 'draw_entries':
          if (['initialized', 'drawn'].includes(await this.draw.status)) return;

          await this.getDrawEntries();
          break;
        case 'winners':
          await this.getWinners();
          break;
        case 'send_mail':
          await this.getTemplates();
          break;
      }
    },

    async getTemplates() {
      this.loading = true;

      await this.fetchTemplates().then(() => {
        this.loading = false;
      });
    },

    async getDraw() {
      this.loading = true;

      await this.fetchDraw({ id: this.$route.params.draw_id }).then(() => {
        this.loading = false;
      });
    },

    async getBundles() {
      this.loading = true;

      await this.fetchBundles({ drawId: this.$route.params.draw_id }).then(
        () => {
          this.loading = false;
        }
      );
    },

    async getDrawEntries() {
      this.loading = true;

      await this.fetchDrawEntries({ drawId: this.$route.params.draw_id }).then(
        () => {
          this.loading = false;
        }
      );
    },

    async getUserEntries() {
      this.loading = true;

      await this.fetchUserEntries({ drawId: this.$route.params.draw_id }).then(
        () => {
          this.loading = false;
        }
      );
    },

    async getWinners() {
      this.loading = true;

      await this.fetchWinners({ drawId: this.$route.params.draw_id }).then(
        () => {
          this.loading = false;
        }
      );
    },

    async getMembers() {
      this.loading = true;

      await this.fetchMembers().then(() => {
        this.loading = false;
      });
    },

    getAcronym(name = '') {
      return acronym(name);
    },

    handleRemove() {
      return false;
    },

    handleUploadImage(values) {
      const payload = {
        id: this.draw.id,
        image: values,
      };

      this.uploadDrawImage(payload)
        .then(() => {
          toasts.success('Draw image successfully uploaded');
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },

    handleOpenDraw() {
      if (['open', 'close', 'initialize', 'drawn'].includes(this.draw.status))
        return;

      if (this.loadingOpen) return;

      this.loadingOpen = true;

      return this.handleUpdateDraw('open');
    },

    handleCloseDraw() {
      if (['new', 'close', 'initialized', 'drawn'].includes(this.draw.status))
        return;

      if (!this.userEntryCount) {
        return toasts.confirm(
          `Are you sure you want to close draw without user entries? You can't undo this action...`,
          async (response) => {
            if (response.isConfirmed) {
              if (this.loadingClose) return;

              this.loadingClose = true;

              return this.handleUpdateDraw('close');
            }
          }
        );
      }

      if (this.loadingClose) return;

      this.loadingClose = true;

      return this.handleUpdateDraw('close');
    },

    handleInitializeDraw() {
      if (['new', 'open', 'initialized', 'drawn'].includes(this.draw.status))
        return;

      if (this.loadingInit) return;

      this.loadingInit = true;

      const payload = {
        drawId: this.draw.id,
        data: {
          members: this.members.length,
        },
      };

      return this.initializeDraw(payload)
        .then(() => {
          toasts.success(`Draw entries successfully prepared for raffle`);
        })
        .catch(() => {
          const { errors, message } = this.drawActionErrors;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loadingInit = false;
        });
    },

    handleUpdateDraw(status) {
      const payload = {
        id: this.draw.id,
        data: {
          status: status,
        },
      };

      this.updateDraw(payload)
        .then(() => {
          toasts.success(`Draw successfully ${status}`);
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loadingOpen = false;
          this.loadingClose = false;
        });
    },

    handleRunDraw() {
      if (['new', 'open', 'close', 'drawn'].includes(this.draw.status)) return;

      return this.$router.push({
        name: 'pick-draw-winner',
        params: {
          draw_id: this.draw.id,
        },
      });
    },

    formatDate(date) {
      return timeUtil.dateFormat(date);
    },

    formatTime(time) {
      return timeUtil.convert24to12(time);
    },
  },

  watch: {
    async draw(values) {
      this.drawData = (await values) as Draw;
    },
  },
});
