
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Form } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';
import ActiveSelect from '@/components/forms/components/ActiveSelect.vue';
import BundleSelect from '@/components/forms/components/BundleSelect.vue';

import toasts from '@/utils/toasts';

import {
  Actions as DrawBundleActions,
  Getters as DrawBundleGetters,
} from '@/store/enums/DrawBundleEnums';
import {
  Actions as BundleActions,
  Getters as BundleGetters,
} from '@/store/enums/BundleEnums';

import { DrawBundleSchema } from '@/schemas/drawbundle.schemas';
import { DrawBundle } from '@/models/DrawBundleModel';
import { Bundle } from '@/models/BundleModel';

const initialValues = {
  account_plan_bundle_id: null,
  draw_id: '',
  quantity: '',
  amount: '',
  active: null,
  description: null,
} as unknown as DrawBundle;

export default defineComponent({
  name: 'add-draw-bundle-form',
  emits: ['dataSubmitted', 'cancelForm'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    Form,
    FormField,
    ActiveSelect,
    BundleSelect,
  },
  data: () => ({
    loading: false,
    isLoad: false,
    form: {} as typeof Form,
    formData: initialValues,
    selectedBundle: {} as unknown as Bundle,
  }),
  async mounted() {
    this.form = this.$refs.addDrawBundleForm as typeof Form;
  },
  computed: {
    ...mapGetters({
      actionErrors: DrawBundleGetters.GET_ACTION_ERROR,
      planBundle: BundleGetters.GET_BUNDLE,
    }),

    formSchema() {
      return DrawBundleSchema;
    },
  },
  methods: {
    ...mapActions({
      create: DrawBundleActions.STORE_DRAW_BUNLDES,
      fetchPlanBundle: BundleActions.FETCH_BUNDLE,
    }),

    handleCancel() {
      this.$emit('cancelForm');
    },

    resetForm() {
      (this.$refs.addDrawBundleForm as typeof Form)?.resetForm();

      // Reset the form
      this.formData = initialValues;
    },
    async submitForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.formData = values;

      const payload = {
        drawId: await this.$route.params.draw_id,
        data: this.formData,
      };

      this.create(payload)
        .then(({ data }) => {
          this.loading = false;
          this.resetForm();

          this.$emit('dataSubmitted', data);
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;

            // Alert when creation request failed
            toasts.error(errors, message, () => {
              return false;
            });
          }
        });
    },
    handleChangeType(value) {
      this.setFieldValue('type', value);
    },
    handleCloseType() {
      this.validateFormField('type');
    },
    handleSelectBundle(value) {
      this.handleBundleSelectProp(value);
      this.setFieldValue('account_plan_bundle_id', value);
    },
    async handleBundleSelectProp(value) {
      if (value) {
        await this.fetchPlanBundle(value).then(async () => {
          this.setDefaultFormValues(await this.planBundle);
        });
      }
    },
    async handleCloseBundle() {
      this.setDefaultFormValues(null);
      this.validateFormField('account_plan_bundle_id');
    },
    handleSelectActive(value) {
      this.setFieldValue('active', value);
    },
    handleCloseActive() {
      this.validateFormField('active');
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    addPrefix(name, prefix = '') {
      return prefix ? `${prefix}_${name}` : name;
    },

    async setDefaultFormValues(bundleData) {
      this.form.setFieldValue('quantity', null);
      this.form.setFieldValue('description', null);
      this.form.setFieldValue('active', null);

      if (bundleData) {
        this.form.setFieldValue('quantity', bundleData.quantity);
        this.form.setFieldValue('description', bundleData.description);
        this.form.setFieldValue('active', 'yes');
      }
    },
  },
});
