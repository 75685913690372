
import { defineComponent } from 'vue';
import useBreakpoints from 'vue-next-breakpoints';
import { mapGetters } from 'vuex';
import Chip from '@/components/Chip.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { UserEntry } from '@/models/UserEntryModel';
import { capitalize, numberFormat } from '@/utils/text';
import { Getters } from '@/store/enums/UserEntryEnums';
import { Draw } from '@/models/DrawModel';

export default defineComponent({
  name: 'entries-tab-content',
  emits: ['loadData'],
  props: {
    loadingState: {
      type: Boolean,
      default: false,
    },
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
  },
  components: {
    Chip,
    DataTable,
  },
  data: () => ({
    dataTable: {
      currentPage: 1,
      totalPages: 10,
      perPage: 15,
      total: 0,
      items: [],
      ordering: false,
      loading: false,
    },
    showCreateModal: false,
    showUpdateModal: false,
    loadingSave: false,
  }),
  computed: {
    ...mapGetters({
      allData: Getters.GET_USER_ENTRIES,
      pagination: Getters.GET_PAGINATION,
      errors: Getters.GET_ACTION_ERRORS,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    dataTableHeader() {
      return [
        {
          name: 'User',
          key: 'user',
          align: 'left',
        },
        {
          name: 'Type',
          key: 'type',
        },
        {
          name: 'No of Entries',
          key: 'quantity',
          align: 'center',
        },
        { name: 'Status', key: 'status', align: 'center' },
        { name: 'Remarks', key: 'remarks' },
      ];
    },
  },
  methods: {
    getStatus(data: UserEntry) {
      if (data?.status === 'active') {
        return {
          type: 'success',
          text: 'Active',
          value: true,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Inactive', value: false };
    },

    formatMoney(amount) {
      return numberFormat(amount, 2);
    },

    formatType(type) {
      return type ? capitalize(type.replace('_', ' ')) : '';
    },

    formatName(name) {
      return name ? capitalize(name) : '';
    },

    newData() {
      this.showCreateModal = true;
    },

    formatList(allData) {
      return allData.map((item) => {
        return {
          id: item.id,
          type: item.type,
          quantity: item.quantity,
          user: `${item.first_name} ${item.last_name}`,
          first_name: item.first_name,
          last_name: item.last_name,
          status: item.status == 'active' ? 'active' : 'inactive',
        };
      });
    },
  },

  watch: {
    async allData(values) {
      if (['initialized', 'drawn'].includes(this.draw.status)) return;

      const rowData = await values;
      this.dataTable.items = this.formatList(rowData);
      this.dataTable.total = rowData.length;
    },

    draw: {
      async handler(values) {
        if (['open', 'new'].includes(await this.draw.status)) return;

        const rowData = await values.draw_user_entries;

        this.dataTable.items = this.formatList(rowData);
        this.dataTable.total = rowData.length;
      },
      immediate: true,
    },
  },
});
