
import { defineComponent } from 'vue';
import useBreakpoints from 'vue-next-breakpoints';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Chip from '@/components/Chip.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import toasts from '@/utils/toasts';
import { DrawEntry } from '@/models/DrawEntryModel';
import { capitalize, numberFormat } from '@/utils/text';
import { Getters, Actions, Mutations } from '@/store/enums/DrawEntryEnums';
import { Draw } from '@/models/DrawModel';

export default defineComponent({
  name: 'entries-tab-content',
  emits: ['loadData'],
  props: {
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
    loadingState: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Chip,
    DataTable,
  },
  data: () => ({
    dataTable: {
      currentPage: 1,
      totalPages: 10,
      perPage: 15,
      total: 0,
      items: [],
      ordering: false,
      loading: false,
    },
    showCreateModal: false,
    showUpdateModal: false,
    loadingSave: false,
  }),
  computed: {
    ...mapGetters({
      allData: Getters.GET_DRAW_ENTRIES,
      pagination: Getters.GET_PAGINATION,
      errors: Getters.GET_ACTION_ERRORS,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    dataTableHeader() {
      return [
        {
          name: 'Code',
          key: 'entry_code',
          align: 'center',
          content: {
            align: 'left',
          },
        },
        {
          name: 'Name',
          key: 'user',
          align: 'center',
        },
        { name: 'Status', key: 'status', align: 'center' },
        {
          name: 'Action',
          key: 'action',
          align: 'center',
          content: {
            maxWidth: '250px',
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      deleteData: Actions.DELETE_DRAW_ENTRIES,
    }),
    ...mapMutations({
      setData: Mutations.SET_DRAW_ENTRY,
    }),

    getStatus(data: DrawEntry) {
      if (data?.status === 'winner') {
        return {
          type: 'success',
          text: 'Winner',
          value: true,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Not picked', value: false };
    },

    formatMoney(amount) {
      return numberFormat(amount, 2);
    },

    formatType(type) {
      return capitalize(type.replace('_', ' '));
    },

    newData() {
      this.showCreateModal = true;
    },

    handleDataChanged() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
      this.$emit('loadData');
    },

    handleCancel() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
    },

    formatName(name) {
      return name ? capitalize(name) : '';
    },

    editData(row: DrawEntry) {
      if (this.draw.status === 'drawn') return;
      this.setData(row);

      this.showUpdateModal = true;

      return false;
    },

    confirmDelete(row: DrawEntry) {
      if (this.draw.status === 'drawn') return;

      return toasts.confirm(
        `Are you sure you want to delete this data? You can't undo this action...`,
        (response) => {
          if (response.isConfirmed) {
            this.handleDeleteData(row);
          }
        }
      );
    },

    handleDeleteData(row: DrawEntry) {
      const params = {
        id: row.entry_id,
        userId: row.user_id,
        drawId: this.$route.params.draw_id,
      };
      this.deleteData(params)
        .then(() => {
          toasts.success('Successfully Deleted');

          this.$emit('loadData');
        })
        .catch(() => {
          const { errors, message } = this.errors;

          toasts.error(errors, message);
        });
    },

    formatList(allData) {
      return allData.map((item) => {
        return {
          id: item.id,
          entry_code: item.entry_code,
          first_name: `${item.first_name}`,
          last_name: `${item.last_name}`,
          status: item.status == 'picked' ? 'winner' : 'not picked',
        };
      });
    },
  },

  watch: {
    async allData(values) {
      if (['initialized', 'drawn'].includes(await this.draw.status)) return;

      const rowData = await values;
      this.dataTable.items = this.formatList(rowData);
      this.dataTable.total = rowData.length;
    },

    draw: {
      async handler(values) {
        if (['open', 'new'].includes(await this.draw.status)) return;

        const rowData = await values.draw_entries;

        this.dataTable.items = this.formatList(rowData);
        this.dataTable.total = rowData.length;
      },
      immediate: true,
    },
  },
});
