import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 p-0" }
const _hoisted_2 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DataTable, {
        "table-data": _ctx.dataTable.items,
        "table-header": _ctx.dataTableHeader,
        "enable-items-per-page-dropdown": true,
        loading: _ctx.dataTable.loading,
        "rows-per-page": 10,
        "row-class": "table-hover",
        ref: "userEntryTable",
        total: _ctx.dataTable.total
      }, {
        "cell-type": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.type ? _ctx.formatType(item.type) : 'Subscription'), 1)
        ]),
        "cell-user": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.formatName(item?.first_name)) + " " + _toDisplayString(_ctx.formatName(item?.last_name)), 1)
        ]),
        "cell-quantity": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.quantity), 1)
        ]),
        "cell-status": _withCtx(({ row: item }) => [
          _createVNode(_component_Chip, {
            type: _ctx.getStatus(item).type,
            text: _ctx.getStatus(item).text
          }, null, 8, ["type", "text"])
        ]),
        "cell-remarks": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.formatType(item.remarks)), 1)
        ]),
        _: 1
      }, 8, ["table-data", "table-header", "loading", "total"])
    ])
  ]))
}