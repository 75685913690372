
import { defineComponent } from 'vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';

import { acronym, capitalize } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';
import { Draw } from '@/models/DrawModel';
import { Getters, Actions } from '@/store/enums/DrawEnums';
import {
  Actions as TemplateActions,
  Getters as TemplateGetters,
  Mutations as TemplateMutations,
} from '@/store/enums/TemplateEnums';
import toasts from '@/utils/toasts';

import FormField from '@/components/forms/FormField.vue';
import EmailDefaultEditorForm from '@/components/forms/settings/EmailDefaultEditorForm.vue';
import { Template } from '@/models/TemplateModel';

import { SendMail } from '@/schemas/settings.schemas';
import { Form } from 'vee-validate';

const initialValues = {
  template: null,
  delivery_date: null,
  delivery_time: null,
  subject: null,
  body: null,
} as unknown as {};

export default defineComponent({
  emits: ['loadData'],
  name: 'winner-tab-content',
  props: {
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
  },
  components: {
    Form,
    FormField,
    EmailDefaultEditorForm,
  },
  data: () => ({
    formData: initialValues,
    templateBody: '',
    loading: false,
    loadSaveTemplate: false,
    formSubmitted: false,
    loadingSave: false,
    loadingDetails: false,
    templateType: {} as unknown as string | string[],
    template: {} as unknown as Template,
    placeholderOptions: [] as unknown as Array<string>,
    form: {} as typeof Form,
  }),
  mounted() {
    this.form = this.$refs.sendNotificationForm as typeof Form;
  },
  computed: {
    ...mapGetters({
      placeholders: TemplateGetters.GET_PLACEHOLDERS,
      actionErrors: Getters.GET_ACTION_ERRORS,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    formSchema() {
      return SendMail;
    },
  },
  methods: {
    ...mapActions({
      sendDrawNotificatons: Actions.SEND_DRAW_NOTIFICATIONS,
      fetchTemplateByName: TemplateActions.FETCH_TEMPLATE_BY_SYSTEM_NAME,
      updateTemplate: TemplateActions.UPDATE_TEMPLATE,
      createTemplate: TemplateActions.CREATE_TEMPLATE,
      fetchPlaceHolder: TemplateActions.FETCH_PLACEHOLDERS,
    }),

    ...mapMutations({
      clearTemplate: TemplateMutations.SET_TEMPLATE,
    }),

    resetForm() {
      this.form.resetForm();

      // Reset the form
      this.formData = initialValues;
      this.templateBody = '';
    },

    ucwords(str) {
      return capitalize(str);
    },

    getAcronym(name = '') {
      return acronym(name);
    },

    formatPlace(index) {
      const place = ['1st', '2nd', '3rd'];
      return place[index - 1];
    },

    async handleFormSubmit(values) {
      if (this.formSubmitted) return;

      this.formSubmitted = true;

      const payload = {
        ...values,
        ...{
          body: this.templateBody,
        },
      };

      return await this.sendDrawNotificatons(payload)
        .then(() => {
          this.formSubmitted = false;
          toasts.success('Notifications successfully sent');
          this.resetForm();
        })
        .catch(() => {
          this.formSubmitted = false;
          const { errors, message } = this.actionErrors;

          toasts.error(errors, message);
        });
    },

    async loadTemplate() {
      const { template, systemName } = this.$route.params;
      this.templateType = template;

      await this.fetchPlaceHolder(systemName);

      await this.fetchTemplateByName({
        templateType: template,
        systemName: systemName,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleUpdateTemplate(values) {
      if (this.loadSaveTemplate) return;

      this.loadSaveTemplate = true;

      const formData = values;

      this.updateTemplate(formData)
        .then(() => {
          this.loadSaveTemplate = false;
        })
        .catch(() => {
          this.loadSaveTemplate = false;
          const { errors, message } = this.actionErrors;

          toasts.error(errors, message);
        });
    },

    handeCreateTemplate(values) {
      if (this.loadSaveTemplate) return;

      this.loadSaveTemplate = true;

      const formData = values;

      this.createTemplate(formData)
        .then(() => {
          this.loadSaveTemplate = false;
        })
        .catch(() => {
          this.loadSaveTemplate = false;
          const { errors, message } = this.actionErrors;

          toasts.error(errors, message);
        });
    },

    async handleSortPlaceholders() {
      let sortedOptions = await this.placeholders.sort((a, b) => {
        let fa = a.toLowerCase(),
          fb = b.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      this.placeholderOptions = sortedOptions;
    },

    handePreviewEmail() {
      return true;
    },
  },
});
