
import { defineComponent } from 'vue';
import { Picture as IconPicture } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'upload-image-modal',
  emits: ['uploadImage', 'removeImage'],
  components: {
    IconPicture,
  },
  data: () => ({
    modal: {} as HTMLElement,
    editMode: false,
    file: '',
    imageDisplay: '',
  }),
  props: {
    modalId: {
      type: String,
      default: 'image-modal',
    },
    title: {
      type: String,
      default: 'Upload Image',
    },
    image: {
      type: String,
      default: '',
    },
  },

  mounted() {
    // setup the modal reference and event listener
    this.modal = document.getElementById(`${this.modalId}`) as HTMLElement;

    if (this.modal) {
      this.modal.addEventListener('hidden.bs.modal', () => {
        this.imageDisplay = this.image as any;
        this.editMode = false;
      });
    }
  },
  methods: {
    onFileHandle(e) {
      this.editMode = true;
      this.file = e.target.files[0] ?? '';
      this.imageDisplay = URL.createObjectURL(e.target.files[0]);
    },
    remove() {
      this.editMode = false;
      this.$emit('removeImage');
    },
    save() {
      this.editMode = false;
      this.$emit('uploadImage', this.file);
    },
  },
  watch: {
    async image(values) {
      const imageData = await values;
      if (typeof imageData !== 'undefined') {
        this.imageDisplay = imageData;
      }
    },
  },
});
