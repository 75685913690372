
import { defineComponent } from 'vue';
import useBreakpoints from 'vue-next-breakpoints';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Chip from '@/components/Chip.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import toasts from '@/utils/toasts';
import { DrawBundle } from '@/models/DrawBundleModel';
import AddDrawBundleModal from '@/components/drawBundles/AddDrawBundleModal.vue';
import { capitalize, numberFormat } from '@/utils/text';
import {
  Getters as BundleGetters,
  Actions as BundleActions,
  Mutations as BundleMutations,
} from '@/store/enums/DrawBundleEnums';
import { Draw } from '@/models/DrawModel';

export default defineComponent({
  name: 'bundle-tab-content',
  emits: ['loadData'],
  props: {
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
    loadingState: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Chip,
    DataTable,
    AddDrawBundleModal,
  },
  data: () => ({
    bundlesTable: {
      currentPage: 1,
      totalPages: 10,
      perPage: 15,
      total: 0,
      items: [],
      ordering: false,
      loading: false,
    },
    showCreateModal: false,
    showUpdateModal: false,
    loadingSave: false,
  }),
  computed: {
    ...mapGetters({
      drawBundles: BundleGetters.GET_DRAW_BUNDLES,
      drawBundle: BundleGetters.GET_DRAW_BUNDLE,
      pagination: BundleGetters.GET_PAGINATION,
      errors: BundleGetters.GET_ACTION_ERROR,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    bundleTableHeader() {
      return [
        {
          name: 'Price',
          key: 'amount',
          align: 'center',
          content: {
            align: 'right',
          },
        },
        {
          name: 'No of Entries',
          key: 'quantity',
          align: 'center',
        },
        {
          name: 'Description',
          key: 'description',
          content: {
            maxWidth: '300px',
          },
        },
        { name: 'Purchase Count', key: 'entries', align: 'center' },
        { name: 'Active', key: 'active', align: 'center' },
        {
          name: 'Action',
          key: 'action',
          align: 'center',
          content: {
            maxWidth: '250px',
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      deleteData: BundleActions.DELETE_DRAW_BUNLDES,
    }),
    ...mapMutations({
      setData: BundleMutations.SET_DRAW_BUNDLE,
    }),

    getStatus(data: DrawBundle) {
      if (data?.active === 'yes') {
        return {
          type: 'success',
          text: 'Active',
          value: true,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Inactive', value: false };
    },

    formatMoney(amount) {
      return numberFormat(amount, 2);
    },

    formatType(type) {
      return capitalize(type.replace('_', ' '));
    },

    newData() {
      this.showCreateModal = true;
    },

    viewData(row: DrawBundle) {
      this.setData(row);
    },

    editData(row: DrawBundle) {
      if (
        ['close', 'open', 'drawn'].includes(this.draw.status) ||
        row.entries
      ) {
        return toasts.alert('Cannot modify/remove draw bundle with entries');
      }

      this.setData(row);

      this.showUpdateModal = true;
    },

    confirmDelete(row: DrawBundle) {
      if (
        ['close', 'open', 'drawn'].includes(this.draw.status) ||
        row.entries
      ) {
        return toasts.alert('Cannot modify/remove draw bundle with entries');
      }

      return toasts.confirm(
        `Are you sure you want to delete this data? You can't undo this action...`,
        (response) => {
          if (response.isConfirmed) {
            this.handleDeleteData(row);
          }
        }
      );
    },

    handleDeleteData(row: DrawBundle) {
      const params = {
        id: row.account_plan_bundle_id,
        drawId: this.$route.params.draw_id,
      };
      this.deleteData(params)
        .then(() => {
          toasts.success('Successfully Deleted');

          this.$emit('loadData');
        })
        .catch(() => {
          const { errors, message } = this.errors;

          toasts.error(errors, message);
        });
    },

    handleDataChanged() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
      this.$emit('loadData');
    },

    handleCancel() {
      this.setData(null);
      this.showCreateModal = false;
      this.showUpdateModal = false;
    },
  },

  watch: {
    async drawBundles(values) {
      const bundleData = await values;
      this.bundlesTable.items = bundleData;
      this.bundlesTable.total = bundleData.length;
    },
  },
});
