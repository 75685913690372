
import { defineComponent } from 'vue';

import { mapActions, mapGetters } from 'vuex';

import { acronym, capitalize } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';
import { Draw } from '@/models/DrawModel';
import { Getters, Actions } from '@/store/enums/DrawWinnerEnums';
import { DrawWinner } from '@/models/DrawWinnerModel';
import ImageUploadModal from '@/components/ImageUploadModal.vue';
import toasts from '@/utils/toasts';
import { Picture as IconPicture } from '@element-plus/icons-vue';

export default defineComponent({
  emits: ['loadData'],
  name: 'winner-tab-content',
  props: {
    draw: {
      type: Object,
      default: () => {
        return {} as unknown as Draw;
      },
    },
  },
  components: {
    ImageUploadModal,
    IconPicture,
  },
  data: () => ({
    bundlesTable: {
      currentPage: 1,
      totalPages: 10,
      perPage: 15,
      total: 0,
      items: [],
      ordering: false,
    },
    formData: {} as unknown as DrawWinner,
    loading: false,
    loadingSave: false,
    loadingDetails: false,
  }),
  computed: {
    ...mapGetters({
      winners: Getters.GET_DRAW_WINNERS,
      errors: Getters.GET_ACTION_ERRORS,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    imageSrc() {
      const user =
        this.winners && this.winners[0]?.avatar_src
          ? this.winners[0]?.avatar_src
          : null;

      return this.winners && this.winners[0]?.winner_image
        ? this.winners[0]?.winner_image_src
        : user;
    },
  },
  methods: {
    ...mapActions({
      uploadImage: Actions.UPLOAD_DRAW_WINNER_PICTURE,
    }),

    ucwords(str) {
      return capitalize(str);
    },

    handleRemoveWinnerImage(values) {
      return false;
    },

    handleUploadWinnerImage(values) {
      const payload = {
        drawId: this.draw.id,
        id: this.winners[0].id,
        image: values,
      };

      this.uploadImage(payload)
        .then(() => {
          toasts.success('Winner image successfully uploaded');
          this.$emit('loadData');
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },

    onPageChanged() {
      console.log('test');
    },

    getAcronym(name = '') {
      return acronym(name);
    },

    formatPlace(index) {
      const place = ['1st', '2nd', '3rd'];
      return place[index - 1];
    },
  },
});
